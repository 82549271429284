const VERSION_APP = import.meta.env.VITE_VERSION_APP as string

const getImagePath = (imageName: string) => `${imageName}?v=${VERSION_APP}`

// GIF
export const loading = getImagePath('/assets/gif/loading.gif')
export const loading_checkout = getImagePath('/assets/gif/loading_checkout.gif')
export const loading_white = getImagePath('/assets/gif/loading_white.gif')
export const shop = getImagePath('/assets/gif/shop.gif')

// IMAGES
export const banking = getImagePath('/assets/image/banking.png')
export const qrCode = getImagePath('/assets/image/qr-code.png')
export const qrApp = getImagePath('/assets/image/qr-app.png')
export const complete = getImagePath('/assets/image/complete.png')
export const banner = getImagePath('/assets/image/banner.jpg')
export const flagVn = getImagePath('/assets/image/flag-vn.png')
export const flagUk = getImagePath('/assets/image/flag-uk.png')

export const btn_next = getImagePath('/assets/image/btn_next.png')
export const btn_prev = getImagePath('/assets/image/btn_prev.png')
export const arrow_down = getImagePath('/assets/image/arrow_down.png')

// LOGO
export const logo_black = getImagePath('/assets/image/logo-black.png')
export const logo_white = getImagePath('/assets/image/logo-white.png')
export const logo_maison_black = getImagePath('/assets/image/logo_maison_black.png')

//KEYBOARD
export const caps = getImagePath('/assets/image/caps.png')
export const remove = getImagePath('/assets/image/remove.png')
export const alpha = getImagePath('/assets/image/alpha.png')
