import { comitHiGD, confirmFulfillmentOrder, confirmOrder, confirmPayment, findOrder, searchProduct } from '@/src/apis/'
import { btn_next, btn_prev } from '@/src/assets'
import { Header, PrintOrder } from '@/src/components'
import {
  APP_INFO,
  CARRIER_MODEL,
  DEV_MODE,
  LANDING_SITE_REF,
  ORDER_PENDING,
  PAYMENT_REFUNDED,
  PAYMENT_TYPE_MODEL
} from '@/src/constants'
import { useApp } from '@/src/context'
import { IAppInfoLocalStorage } from '@/src/types'
import { printHTML, showToast, useLocalStorage } from '@/src/utils'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { Keyboard } from '../components'

const Printer: React.FC = () => {
  const { onLoading } = useApp()

  const { t } = useTranslation()

  const divRef = useRef<HTMLDivElement>(null)
  const htmlRef = useRef<HTMLDivElement>(null)

  // @ts-ignore
  const [appInfo, setAppInfo] = useLocalStorage<IAppInfoLocalStorage | null>(APP_INFO, null)
  // @ts-ignore
  const [devMode, setDevMode] = useLocalStorage<IDevModeLocalStorage | null>(DEV_MODE, null)

  const [orderId, setOrderId] = useState<string>('')
  const [printTemplate, setPrintTemplate] = useState<any>(null)
  const [order, setOrder] = useState<any>(null)

  const onNext = async () => {
    if (!orderId) {
      shakeDiv()
    } else {
      onLoading(true)

      const res = await findOrder(orderId)
      const temp = res?.data?.data

      if (res?.is_error || !temp || temp.landingSiteRef != LANDING_SITE_REF) {
        onLoading(false)
        showToast('warn', t('toast.orderNotFound'))
        shakeDiv()
      } else {
        setOrder(temp)
        showToast('success', t('toast.orderFound'))
      }

      onLoading(false)
    }
  }

  const onSubmit = async () => {
    onLoading(true)

    if (order?.shipmentStatus == ORDER_PENDING) {
      // XÁC NHẬN GIAO HÀNG
      await onFulfillment(order)
    }

    if (!order?.isConfirmed) {
      // XÁC NHẬN ĐƠN HÀNG
      await confirmOrder(order?.orderId)
    }

    if (order?.amountPaid === 0 && !order?.isCancel && order?.paymentStatusId != PAYMENT_REFUNDED) {
      // XÁC NHẬN THANH TOÁN
      await confirmPayment(order?.orderId)
    }

    onPrintTemplate(order)
    onSubmitHiGD(order)

    onLoading(false)
  }

  const shakeDiv = async () => {
    if (divRef?.current) {
      divRef.current.classList.add('animate-shake')
      await new Promise((resolve) => setTimeout(resolve, 500)) // Wait for animation to complete
      divRef.current.classList.remove('animate-shake')
    }
  }

  const onSubmitHiGD = async (data: any) => {
    let temp = data?.noteAttributes?.filter((item: any) => item?.name?.indexOf('x-haravan-epcs') !== -1)

    const Epcs = temp.flatMap((item: any) => {
      return item.value.split(',').map((epc: any) => ({
        EPC: epc,
        Flag: 0 //Flag :0:epc, 1:barcode
      }))
    })

    if (devMode?.isDisableHigd) return
    const payload = {
      storeName: appInfo?.location?.name || '',
      deviceCode: appInfo?.deviceCode || '',
      securityEpc: Epcs, //Flag :0:epc, 1:barcode
      orderType: 1 //Status（1：receive；2：return）
    }

    comitHiGD(payload)
  }

  const onPrintTemplate = async (data: any) => {
    // Lấy danh sách barcode từ sản phẩm trong đơn hàng
    const barcodes = data?.orderProducts?.map((item: any) => item.barcode).join(',')

    // Tìm sản phẩm dựa trên barcode và vị trí
    const product = await searchProduct(barcodes, appInfo?.location?.id)

    // Nếu tìm thấy sản phẩm trong cơ sở dữ liệu
    if (!product?.is_error) {
      const mergedData = data?.orderProducts?.map((order: any) => {
        const productDetails = product?.data?.data?.data?.find((product: any) => product.barcode === order.barcode)

        // Chỉ lấy tham số oldPrice từ productDetails
        return {
          ...order,
          oldPrice: productDetails?.oldPrice ?? null
        }
      })

      // Nếu dữ liệu đã hợp nhất tồn tại
      if (mergedData?.length) {
        const temp = {
          ...data,
          orderProducts: mergedData
        }

        setPrintTemplate(temp) 
      }
    } else {
      setPrintTemplate(data)
    }
  }

  const onFulfillment = async (order: any) => {
    try {
      const fulfillPayload = {
        carrierShippingService: CARRIER_MODEL,
        order: {
          ...order,
          ...PAYMENT_TYPE_MODEL,
          orderProducts: null,

          locationId: appInfo?.location?.id || '',
          assignedLocationId: appInfo?.location?.id || '',
          totalWeight: order.totalWeight || 0
        },
        isSentEmail: true,
        listDetail: order?.orderProducts
      }
      await confirmFulfillmentOrder(order?.orderId, fulfillPayload)
    } catch (error) {
      console.log(error)
    }
  }

  const onPrint = async () => {
    if (htmlRef.current === null) return
    const dataPrint = htmlRef.current
    dataPrint.classList.remove('absolute')
    dataPrint.classList.remove('-z-10')

    await printHTML(dataPrint)
    showToast('success', t('toast.printSuccess'))
  }

  useEffect(() => {
    if (htmlRef.current) onPrint()
  }, [printTemplate])

  return (
    <div className='layout pt-40'>
      <Header title='settings.order' />
      <Keyboard
        type={'deviceCode'}
        data={orderId}
        setData={setOrderId}
        divRef={divRef}
        title={'settings.inputOrder'}
        placeHolder={'settings.enterOrder'}
        isCaps={true}
        length={30}
      />
      <Footer onNext={onNext} onSubmit={onSubmit} order={order} />
      {printTemplate && (
        <div className='absolute -top-[300rem] -z-10'>
          <div className='w-[540px] pr-4' ref={htmlRef}>
            <PrintOrder order={printTemplate} location={appInfo?.location} />
          </div>
        </div>
      )}
    </div>
  )
}

//viewChildren

const Footer: React.FC<any> = ({ onNext, onSubmit, order }) => {
  const navigate = useNavigate()

  const onPrevious = () => {
    navigate(-1)
  }
  const { t } = useTranslation()

  const txtPrint = useMemo(() => {
    if (order?.amountPaid === 0 && !order?.isCancel && order?.paymentStatusId != PAYMENT_REFUNDED) {
      return 'paymentCompleted.confirmAndRePrint'
    } else {
      return 'paymentCompleted.rePrint'
    }
  }, [order])

  return (
    <div className='z-50 fixed bottom-0 w-full flex bg-white border-t-2 py-4 px-10 justify-between'>
      <button
        style={{ '--btn-prev': `url(${btn_prev})` } as React.CSSProperties}
        onClick={onPrevious}
        className='button-prev footer'
      >
        <p className='content-accent700 responsive-text-small-2'>{t('back')}</p>
      </button>

      {order && (
        <button
          onClick={onSubmit}
          className={'btn-custom background-accent100 h-[96px] w-[35%] rounded-md flex justify-center items-center'}
        >
          <p className={'responsive-text-small-3 content-accent700'}>{t(txtPrint)}</p>
        </button>
      )}

      <button
        style={{ '--btn-next': `url(${btn_next})` } as React.CSSProperties}
        onClick={onNext}
        className='button-next footer'
      >
        <p className='text-white responsive-text-small-2'>{t('confirm')}</p>
      </button>
    </div>
  )
}

export default Printer
