import { comitHiGD } from '@/src/apis'
import { searchProduct } from '@/src/apis/'
import { complete, loading_checkout, loading_white, qrApp } from '@/src/assets/'
import { Header, PrintOrder } from '@/src/components'
import { APP_INFO, DEV_MODE, ID_CART } from '@/src/constants'
import { useApp, useRFID, useUser } from '@/src/context'
import { IAppInfoLocalStorage, IDevModeLocalStorage } from '@/src/types'
import { printHTML, showToast, useLocalStorage } from '@/src/utils'
import { useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
const TIME_OUT_PAYMENT_COMPLETE = 60000 * 2 // 2 PHÚT

const Completed: React.FC = () => {
  const { t } = useTranslation()

  const isInitialMount = useRef(true)
  // @ts-ignore
  const [idCart, setIdCart] = useLocalStorage<string | null>(ID_CART, null)
  // @ts-ignore
  const [devMode, setDevMode] = useLocalStorage<IDevModeLocalStorage | null>(DEV_MODE, null)
  // @ts-ignore
  const [appInfo, setAppInfo] = useLocalStorage<IAppInfoLocalStorage | null>(APP_INFO, null)

  const { onSetOrder, order, onClearCart } = useApp()
  const { setUserInfo, onSetBill, setPhone } = useUser()
  const { RFID, setRFID } = useRFID()

  const [isComplete, setIsComplete] = useState(false)
  const [printTemplate, setPrintTemplate] = useState<any>(null)
  const [isPrinting, setIsPrinting] = useState<boolean>(false)

  const htmlRef = useRef<HTMLDivElement>(null)
  const ORDER_TEMP = useRef<any>(null)
  const RFID_TEMP = useRef<any>(null)

  useEffect(() => {
    // PREVENT CALL API 2 TIMES IN STRICTMODE
    if (isInitialMount.current) {
      onLoadData()

      isInitialMount.current = false
    }
  }, [order])

  useEffect(() => {
    if (htmlRef.current) onPrint()
  }, [printTemplate])

  useEffect(() => {
    ORDER_TEMP.current = order
    RFID_TEMP.current = RFID

    return () => {
      ORDER_TEMP.current = null
      RFID_TEMP.current = null
    }
  }, [])

  const onPrintTemplate = async (data: any) => {
    if (devMode?.isDisablePrinter) return

    // Lấy danh sách barcode từ sản phẩm trong đơn hàng
    const barcodes = data?.orderProducts?.map((item: any) => item.barcode).join(',')

    // Tìm sản phẩm dựa trên barcode và vị trí
    const product = await searchProduct(barcodes, appInfo?.location?.id)

    // Nếu tìm thấy sản phẩm trong cơ sở dữ liệu
    if (!product?.is_error) {
      const mergedData = data?.orderProducts?.map((order: any) => {
        const productDetails = product?.data?.data?.data?.find((product: any) => product.barcode === order.barcode)

        // Chỉ lấy tham số oldPrice từ productDetails
        return {
          ...order,
          oldPrice: productDetails?.oldPrice ?? null
        }
      })

      // Nếu dữ liệu đã hợp nhất tồn tại
      if (mergedData?.length) {
        const temp = {
          ...data,
          orderProducts: mergedData
        }

        setPrintTemplate(temp) 
      }
    } else {
      setPrintTemplate(data)
    }
  }

  const onCompleteCheckout = async () => {
    try {
      await onPrintTemplate(order)

      setIsComplete(true)
      setIdCart(null)
      onSetOrder(null)
      onClearCart()
      setRFID([])
      setUserInfo(null)
      setPhone('')
      onSetBill()
    } catch (err) {
      console.log(err)
    }
  }

  const onSubmitHiGD = async (data: any) => {
    if (devMode?.isDisableHigd) return
    const payload = {
      storeName: appInfo?.location?.name || '',
      deviceCode: appInfo?.deviceCode || '',
      securityEpc: data?.map((item: any) => ({ EPC: item.tag, Flag: 0 })), //Flag :0:epc, 1:barcode
      orderType: 1 //Status（1：receive；2：return）
    }
    comitHiGD(payload) // KHÔNG AWAIT HiGD
  }

  const onLoadData = async () => {
    onSubmitHiGD(RFID)
    await onCompleteCheckout()
  }

  const onRePrint = async () => {
    setIsPrinting(true)
    onSubmitHiGD(RFID_TEMP.current)
    await onPrintTemplate(ORDER_TEMP.current)
    setIsPrinting(false)
    showToast('success', t('toast.printSuccess'))
  }

  const onPrint = async () => {
    if (htmlRef.current === null) return

    const dataPrint = htmlRef.current
    dataPrint.classList.remove('absolute')
    dataPrint.classList.remove('-z-10')

    await printHTML(dataPrint)
  }

  const customerName = useMemo(() => {
    let temp = appInfo?.language === 'vi' ? 'bạn' : ''

    if (ORDER_TEMP?.current?.customerInfo?.fullname) {
      return ORDER_TEMP?.current?.customerInfo?.fullname === 'WALK-IN'
        ? temp
        : ORDER_TEMP?.current?.customerInfo?.fullname
    }

    return temp
  }, [ORDER_TEMP?.current, appInfo?.language])

  return (
    <div className='layout pb-40 bg-white static'>
      <Header title='paymentCompleted.payment' />
      {!isComplete ? (
        <img style={{ height: '48rem', marginTop: '5rem' }} src={loading_checkout} alt='img' />
      ) : (
        <>
          <p className='responsive-text-small mt-20'>{t('paymentCompleted.paymentComplete').toUpperCase()}</p>
          <p className='responsive-text-small-2 font-bold content-accent700'>
            {t('invoice', { invoiceNo: ORDER_TEMP.current?.orderNumber })}
          </p>

          <div className='flex flex-col justify-center items-center gap-10'>
            <p className='responsive-text-small text-center '>
              {t('paymentCompleted.dontForgetToTakeReceiptAndBelonging')}
            </p>

            <img style={{ height: '512px', width: '768px' }} src={complete} alt='img' />

            <div>
              <p className='responsive-text-small text-center'>
                <TitleLine
                  title={t('paymentCompleted.thankYouForShoppingWithUs', {
                    customerName,
                    store: 'URBAN REVIVO Vincom Đồng Khởi.'
                  })}
                />
              </p>
              <p className='responsive-text-small text-center mt-2'>
                <TitleLine title={t('paymentCompleted.pleaseInstallApp')} />
              </p>
            </div>
          </div>

          <img style={{ height: '300px', width: '300px', marginTop: '1.5rem' }} src={qrApp} alt='img' />

          <p className='responsive-text-small-2 text-center mt-2'>
            <TitleLine title={t('paymentCompleted.scanQrCode')} />
          </p>

          {printTemplate && (
            <div className='absolute -top-[300rem] -z-10'>
              <div className='w-[540px] pr-4' ref={htmlRef}>
                <PrintOrder order={printTemplate} location={appInfo?.location} />
              </div>
            </div>
          )}
        </>
      )}

      {isComplete && <Footer onRePrint={onRePrint} isPrinting={isPrinting} />}
    </div>
  )
}

//viewChildren
const Footer: React.FC<any> = ({ onRePrint, isPrinting }) => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  useEffect(() => {
    const timer = setTimeout(() => {
      // sau thời gian 120s, auto back màn hình home
      navigate('/home')
    }, TIME_OUT_PAYMENT_COMPLETE)

    return () => clearTimeout(timer)
  }, []) // Empty dependency array ensures this runs only once

  const onBack = () => {
    navigate('/home')
  }

  return (
    <div className='fixed bottom-0 w-full flex bg-white border-t-2 py-4 px-8 justify-between'>
      <div className='h-[114px] w-[22%]'></div>

      <button onClick={onBack} className='bg-white w-[35%] py-6 rounded-lg border-2 border-[#0047BB] '>
        <p className='responsive-text-small-2 content-accent700'>{t('paymentCompleted.close')}</p>
      </button>

      <button
        onClick={onRePrint}
        className={'btn-custom background-accent100 h-[114px] w-[22%] rounded-md flex justify-center items-center'}
      >
        {isPrinting ? (
          <img style={{ width: '50%' }} src={loading_white} alt='loading_white' />
        ) : (
          <p className={'responsive-text-small-3 content-accent700'}>{t('paymentCompleted.rePrint')}</p>
        )}
      </button>
    </div>
  )
}

const TitleLine = ({ title }: any) => {
  const titleLines = title.split('\n')
  return (
    <>
      {titleLines.map((line: any, index: any) => (
        <span key={index}>
          {line}
          {index < titleLines.length - 1 && <br />}
        </span>
      ))}
    </>
  )
}

export default Completed
