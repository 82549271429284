import { TOKEN } from '@/src/constants'
import { IGetApiOptions, IPostApiOptions } from '@/src/types'
import * as Sentry from '@sentry/browser'
import axios, { AxiosResponse } from 'axios'

const URL_SHOP = import.meta.env.VITE_APP_DOMAINBE as string

// Tạo instance axios với timeout và thiết lập cơ bản
const axiosInstance = axios.create({
  timeout: 10000 // timeout 10s
})

// Interceptor retry
axiosInstance.interceptors.response.use(
  (response) => response, // Xử lý nếu thành công
  async (error) => {
    const config = error.config
    if (!config._retry) {
      config._retry = true
      try {
        return await axiosInstance(config) // Thử lại 1 lần
      } catch (retryError) {
        throw retryError // Ném lỗi nếu retry cũng thất bại
      }
    }
    throw error // Nếu đã retry 1 lần mà vẫn lỗi, ném lỗi
  }
)

const createHeaders = (includeAuthorization: boolean): Record<string, string> | undefined => {
  const contentType = { 'Content-Type': 'application/json' }
  if (!includeAuthorization) {
    return contentType
  }

  const token = window.localStorage.getItem(TOKEN)
  const authorizationToken = token ? JSON.parse(token) : ''
  const authorizationHeader = { Authorization: `Bearer ${authorizationToken}` }

  return { ...contentType, ...authorizationHeader }
}

const handleResponse = (response: AxiosResponse) => {
  const { status, data } = response
  const isSuccessStatus = status === 200 || status === 201 || !data?.is_error

  if (isSuccessStatus) {
    return data
  } else if (status === 401) {
    window.location.href = '/admin'
    window.localStorage.removeItem(TOKEN)
    return null
  } else {
    throw new Error(`Request failed with status: ${status}`)
  }
}

const handleError = (error: any, apiUrl: string): boolean => {
  Sentry.captureException(error, {
    tags: {
      location: 'fetchData' // Xác định vị trí xảy ra lỗi
    },
    extra: {
      apiUrl, // Thêm thông tin về URL API
      errorMessage: error.message // Thêm chi tiết lỗi
    },
    level: 'error' // Mức độ lỗi
  })

  if (error.response?.status === 401) {
    window.location.href = '/admin'
    window.localStorage.removeItem(TOKEN)
  }

  return false
}

// METHODS

const GET_API = async ({
  endpoint,
  isIncludeAuthorization = true,
  isEndpointShop = true,
  timeout = 10000
}: IGetApiOptions): Promise<any> => {
  const apiUrl = isEndpointShop ? `${URL_SHOP}/${endpoint}` : endpoint

  try {
    const headers = createHeaders(isIncludeAuthorization)
    const response = await axiosInstance.get(apiUrl, {
      headers,
      timeout: timeout || axiosInstance.defaults.timeout
    })
    return handleResponse(response)
  } catch (error) {
    return handleError(error, apiUrl)
  }
}

const POST_API = async ({
  endpoint,
  data,
  isIncludeAuthorization = true,
  isEndpointShop = true,
  timeout = 10000
}: IPostApiOptions): Promise<any> => {
  const apiUrl = isEndpointShop ? `${URL_SHOP}/${endpoint}` : endpoint

  try {
    const headers = createHeaders(isIncludeAuthorization)
    const response = await axiosInstance.post(apiUrl, data, {
      headers,
      timeout: timeout || axiosInstance.defaults.timeout
    })
    return handleResponse(response)
  } catch (error) {
    return handleError(error, apiUrl)
  }
}

const PUT_API = async ({
  endpoint,
  data,
  isIncludeAuthorization = true,
  isEndpointShop = true,
  timeout = 10000
}: IPostApiOptions): Promise<any> => {
  const apiUrl = isEndpointShop ? `${URL_SHOP}/${endpoint}` : endpoint

  try {
    const headers = createHeaders(isIncludeAuthorization)
    const response = await axiosInstance.put(apiUrl, data, {
      headers,
      timeout: timeout || axiosInstance.defaults.timeout
    })
    return handleResponse(response)
  } catch (error) {
    return handleError(error, apiUrl)
  }
}

export { GET_API, POST_API, PUT_API }
