import { btn_next, btn_prev } from '@/src/assets'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import './styles.css'

interface IFooter {
  onNext?: () => void
  onBack?: () => void
  nextTitle?: string
  backTitle?: string
  isDisabledNext?: boolean
  isDisabledBack?: boolean
  isNext?: boolean
}

const Footer: React.FC<IFooter> = ({
  onNext,
  onBack,
  nextTitle,
  backTitle,
  isDisabledBack,
  isDisabledNext,
  isNext = true
}) => {
  const { t } = useTranslation()

  const navigate = useNavigate()

  const onPrevious = () => {
    onBack ? onBack() : navigate(-1)
  }

  return (
    <div className='z-50 fixed bottom-0 w-full flex bg-white border-t-2 py-4 px-20 justify-between'>
      {!isDisabledBack && (
        <button
          style={{ '--btn-prev': `url(${btn_prev})` } as React.CSSProperties}
          onClick={onPrevious}
          className='button-prev footer'
        >
          <p className='content-accent700 responsive-text-small-2'>{t(backTitle || 'back')}</p>
        </button>
      )}

      {!isDisabledNext && isNext && onNext && (
        <button
          style={{ '--btn-next': `url(${btn_next})` } as React.CSSProperties}
          onClick={() => (isDisabledNext ? null : onNext())}
          className='button-next footer'
        >
          <p className='text-white responsive-text-small-2'>{t(nextTitle || 'confirm')}</p>
        </button>
      )}
    </div>
  )
}

export default Footer
