import { POST_API, GET_API } from '../configApi'

export const getLoyaltyProfile = async (payload: any): Promise<any> => {
  return await POST_API({
    endpoint: `admin/integrationmaison/adapter-api/loyalty/profile`,
    data: payload,
    timeout: 3000
  })
}

export const getCustomerProfile = async (customerId: string): Promise<any> => {
  return await GET_API({
    endpoint: `admin/com_api/api/orders/customers/${customerId}/simple`
  })
}

export const createCustomer = async (payload: any): Promise<any> => {
  return await POST_API({
    endpoint: `admin/com_api/api/pos/customers`,
    data: payload
  })
}
