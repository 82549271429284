import { getLogin, getToken } from '@/src/apis/auth'
import { banner, logo_white } from '@/src/assets'
import { Button } from '@/src/components'
import { TOKEN } from '@/src/constants'
import { useApp } from '@/src/context'
import { IAppInfoLocalStorage } from '@/src/types'
import { decodeToken, showToast, useLocalStorage } from '@/src/utils'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'

const Admin: React.FC = () => {
  const { t } = useTranslation()

  const { onLoading, setStaff } = useApp()
  // @ts-ignore
  const [token, setToken] = useLocalStorage<IAppInfoLocalStorage | string>(TOKEN, '')
  const [code, setCode] = useState<string>('')

  const navigate = useNavigate()

  const location = useLocation()

  useEffect(() => {
    // Handle the authentication callback logic here
    const query = new URLSearchParams(location.search)
    const code = query.get('code')
    if (code) setCode(code)
  }, [location.search])

  useEffect(() => {
    if (code) onGetToken(code)
  }, [code])

  const onAuthenticated = async () => {
    onLoading(true)
    const res = await getLogin()
    if (!res?.is_error) window.location.href = res?.data?.loginUrl
    onLoading(false)
  }

  const onGetToken = async (code: any) => {
    onLoading(true)
    const res = await getToken(code)
    if (!res?.is_error) {
      setToken(res?.data?.sessionToken)

      // DECODE TOKEN GET INFO USER
      let temp = decodeToken(res?.data?.sessionToken)
      setStaff({
        email: temp?.email,
        name: temp?.name,
        userid: temp?.userid
      })

      navigate('/home')
    } else {
      showToast('warn', t('toast.loginFail'))
    }
    onLoading(false)
  }

  return (
    <div className='layout pb-40 bg-white'>
      <div
        style={{ '--bg-img': `url(${banner})` } as React.CSSProperties}
        className='relative layout w-full flex justify-center bg-white home-banner h-[600px]'
      >
        <div className='absolute bottom-20'>
          <img style={{ height: '3.3rem' }} src={logo_white} alt={'logo'} />
        </div>
      </div>

      <Button className='mt-20' onClick={() => onAuthenticated()} title='admin.login' />
    </div>
  )
}

export default Admin
