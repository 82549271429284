import { getCurrentDate } from '@/src//utils'
import { getVerision } from '@/src/apis'
import { arrow_down, banner, logo_white } from '@/src/assets'
import { Button } from '@/src/components'
import { APP_INFO, DEV_MODE, ID_CART, LANGUAGES } from '@/src/constants'
import { useApp, useRFID, useUser } from '@/src/context'
import { IAppInfoLocalStorage, IDevModeLocalStorage } from '@/src/types'
import { showToast, useLocalStorage } from '@/src/utils'
import i18next from 'i18next'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'
import './styles.css'

const ENVIRONTMENT = import.meta.env.VITE_ENVIRONMENT as string
const VERSION_APP = import.meta.env.VITE_VERSION_APP as string

const Home: React.FC = () => {
  const location = useLocation()

  // @ts-ignore
  const [devMode, setDevMode] = useLocalStorage<IDevModeLocalStorage | null>(DEV_MODE, null)

  const { t } = useTranslation()
  // @ts-ignore
  const [idCart, setIdCart] = useLocalStorage<string | null>(ID_CART, null)
  // @ts-ignore
  const [appInfo, setAppInfo] = useLocalStorage<IAppInfoLocalStorage | null>(APP_INFO, null)

  const { onSetOrder, onClearCart } = useApp()
  const { setUserInfo, setPhone, onSetBill } = useUser()
  const { onConnectRFID, setRFID, setIsReadingRFID } = useRFID()

  const navigate = useNavigate()

  const onNavigate = (path: string) => {
    navigate(path)
  }

  useEffect(() => {
    if (location.pathname === '/home') {
      if (process.env.NODE_ENV === 'production') {
        onCheckVersion()

        if (ENVIRONTMENT === 'production') {
          // @ts-ignore
          clarity('stop')
        }
      }
    }
  }, [location])

  const onCheckVersion = async () => {
    let res = await getVerision()
    if (res?.version != VERSION_APP) {
      window.location.reload()
    }
  }

  const onStart = () => {
    if (ENVIRONTMENT === 'production') {
      // @ts-ignore
      clarity('start')
    }

    onClearCart()
    setRFID([])
    setIdCart(null)
    onSetOrder(null)
    setUserInfo(null)
    setPhone('')
    onSetBill()
    setIsReadingRFID(false)
    //
    onNavigate('/login-phone')

    if (!devMode?.isDisableRFID) {
      onConnectRFID(appInfo)
    }
  }

  const isDisabled = useMemo(() => {
    const { deviceCode, location, paymeCode, higd, rfid } = appInfo || {}
    const requiredFields = [deviceCode, location, paymeCode, higd]

    return devMode?.isDisableRFID
      ? requiredFields.some((field) => !field)
      : requiredFields.concat(rfid).some((field) => !field)
  }, [appInfo, devMode])

  const titleLines = t('home.welcomeNote').split('\n')

  return (
    <div className='layout pb-40 bg-white'>
      <div
        style={{ '--bg-img': `url(${banner})` } as React.CSSProperties}
        className='relative layout w-full flex justify-center bg-white home-banner h-[600px]'
      >
        <div className='absolute bottom-20'>
          <img style={{ height: '3.3rem' }} src={logo_white} alt={'logo'} />
        </div>
      </div>

      <div className='layout w-full'>
        <Language />

        <p className={'responsive-text-small mt-36 text-center'}>
          {/* {t(`home.welcomeTo`)} {BRAND} */}
          {t(`home.welcomeToMessage`)}
        </p>
        <p className={'font-normal responsive-text-small-4 text-center italic mt-5'}>
          {titleLines.map((line, index) => (
            <span key={index}>
              {line}
              {index < titleLines.length - 1 && <br />}
            </span>
          ))}
        </p>
        {/* <p className={'font-normal mt-6 responsive-text'}>{t(`home.selfCheckout`)}</p> */}

        <Button onClick={onStart} className='mt-20' title='home.start' isDisabled={isDisabled} />
      </div>

      <Footer />
    </div>
  )
}

//viewChildren

const Language: React.FC<any> = () => {
  const { t } = useTranslation()

  const [appInfo, setAppInfo] = useLocalStorage<IAppInfoLocalStorage | null>(APP_INFO, null)

  const [isOpen, setIsOpen] = useState<boolean>(false)

  const dropdownRef = useRef<HTMLDivElement | null>(null)

  const toggleDropdown = () => setIsOpen(!isOpen)

  const onChangeLanguage = async (newLang: string) => {
    i18next.changeLanguage(newLang)

    setAppInfo({
      ...appInfo,
      language: newLang
    } as IAppInfoLocalStorage)

    showToast('success', t('toast.languageUpdated'))
    setIsOpen(false)
  }

  const handleClickOutside = (event: any) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
      setIsOpen(false)
    }
  }

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside)

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])

  const selectedOption = useMemo(() => {
    if (!appInfo?.language) return LANGUAGES.find((item) => item.lang === 'vi')
    return LANGUAGES.find((item) => item.lang === appInfo?.language)
  }, [appInfo])

  return (
    <div className='flex w-full justify-end mt-10 px-10'>
      <button className='flex items-center justify-center gap-2'>
        {/* <p className={'text-3xl	'}>{t(`settings.language`)}:</p> */}
        <p className={'text-3xl	'}>Language / Ngôn ngữ:</p>
        <div ref={dropdownRef} className='relative inline-block w-64'>
          <div
            onClick={toggleDropdown}
            className='flex items-center justify-between w-full text-left border-b border-gray-300 bg-white px-4 py-2'
          >
            <div className='flex gap-4'>
              <p className='text-3xl text-[#0047BB]'>{selectedOption?.title}</p>
            </div>

            <img style={{ height: '0.5rem' }} src={arrow_down} alt={'arrow_down'} />
          </div>
          {isOpen && (
            <div className='absolute mt-2 w-full bg-white'>
              {LANGUAGES?.map((val, index) => {
                const isSelected = val?.lang === selectedOption?.lang
                return (
                  <div
                    key={index}
                    onClick={() => onChangeLanguage(val?.lang)}
                    className={`flex items-center w-full px-4 py-4 hover:bg-gray-100 gap-4 ${isSelected ? 'bg-blue-200' : ''}`}
                  >
                    <p className={`text-3xl ${isSelected ? 'text-[#0047BB]' : ''}`}>{val?.title}</p>
                  </div>
                )
              })}
            </div>
          )}
        </div>
      </button>
    </div>
  )
}
const Footer: React.FC<any> = ({}) => {
  const { t } = useTranslation()

  const [appInfo, setAppInfo] = useLocalStorage<IAppInfoLocalStorage | null>(APP_INFO, null)
  const [lang, setLang] = useState<string>(appInfo?.language || 'en')
  const [clickCount, setClickCount] = useState(0)
  const [timer, setTimer] = useState<NodeJS.Timeout | null>(null)

  const navigate = useNavigate()

  const onNavigate = () => {
    // Nếu có lần click trước, reset lại timer
    if (timer) {
      clearTimeout(timer)
    }

    // Tăng số lần click và bắt đầu lại timer
    setClickCount((prevCount) => prevCount + 1)

    // Đặt lại bộ đếm sau 5 giây nếu không click liên tục
    const newTimer = setTimeout(() => {
      setClickCount(0)
    }, 2000)
    setTimer(newTimer)
  }

  // Sử dụng useEffect để điều hướng khi clickCount đạt 5
  useEffect(() => {
    if (clickCount === 5) {
      navigate('/settings')
    }
  }, [clickCount, navigate])

  useEffect(() => {
    // Clean up the timer when the component unmounts or updates
    return () => {
      if (timer) {
        clearTimeout(timer)
      }
    }
  }, [timer])
  // @ts-ignore

  const onChangeLanguage = useCallback(async () => {
    const newLang = lang === 'vi' ? 'en' : 'vi'
    setLang(newLang)
    i18next.changeLanguage(newLang)

    setAppInfo({
      ...appInfo,
      language: newLang
    } as IAppInfoLocalStorage)

    showToast('success', t('toast.languageUpdated'))
  }, [lang])

  // @ts-ignore
  const selectedOption = useMemo(() => {
    return LANGUAGES.find((item) => item.lang === lang)
  }, [appInfo])

  return (
    <div className='fixed bottom-0 w-full flex bg-white border-t-2 py-4 px-14 justify-between items-center h-32'>
      <p onClick={onNavigate} className={'text-xl'}>
        {getCurrentDate()}
      </p>
      {/* <button className='flex items-center justify-center gap-2'>
        <div
          onClick={onChangeLanguage}
          className='flex items-center justify-between w-full text-left border-b border-gray-300 bg-white px-4 py-2'
        >
          <div className='flex gap-4 items-center'>
            <img style={{ height: '3rem' }} src={selectedOption?.icon} alt={selectedOption?.title} />
            <p className='responsive-text'>{selectedOption?.title}</p>
          </div>
        </div>
      </button> */}
    </div>
  )
}

export default Home
